
.demo-ruleForm {
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #333;
    }
}
.big-title {
    margin: 40px 0;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
        content: '';
        width: 4px;
        height: 16px;
        background: #2DC079;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
}
.form-item-1 {
    font-size: 16px;
    .item-left-box {
        display: flex;
        flex-direction: column;
    }
    .item-left-first {
        width: 110px;
        height: 40px;
        line-height: 40px;
        text-align: right;
        margin-right: 16px;
        margin-bottom: 22px;
    }
    .item-right {
        width: 1%;
        flex: 1;
        display: flex;
    }
    .item-text {
        width: 201px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 40px;
    }
}
.form-item-2 {
    display: flex;
    flex-wrap: wrap;
    .item-box {
        margin-right: 40px;
    }
    .item-text {
        width: 182px;
        line-height: 1;
        margin-bottom: 10px;
        text-align: center;
        font-size: 16px;
    }
    .item-input {
        width: 182px;
    }
}
.prompt-text {
    font-size: 16px;
    margin-left: 4px;
    color: #999;
    height: 40px;
    line-height: 40px;
}
.number-input1 {
    width: 100%;
}
::v-deep .number-input1 {
    &.isError {
        .el-input__inner {
            border-color: #FD4446;
        }
    }
}
.big-title-prompt {
    color: #999;
    font-size: 14px;
    margin-left: 6px;
    margin-right: 10px;
}
